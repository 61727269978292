<template>
  <v-container class="py-8 px-6 body-color" fluid>
    <v-row class="justify-center">
      <div>
        <div
          align="center"
          :class="{
            'ma-0': $vuetify.breakpoint.smAndDown,
            'ma-5': $vuetify.breakpoint.mdAndUp,
          }"
          xs12
          sm6
          md4
        >
          <div class="body">
            <div class="logo-homepage">
              <v-img
                width="70%"
                height="70%"
                class="img-body"
                :src="require('../../assets/bus-homepage.svg')"
                contain
              />
            </div>
           <div class="body-content">
              <span class="span-title">{{ $t("JoinUs") }}</span>
              <p class="p-text">{{ $t("takeajourneywithus") }}</p>

              <div align="left">
                <label class="label-content">{{ $t("Language") }}</label>
                <v-select
                  height="30"
                  :placeholder="$t('SelectLanguage')"
                  v-bind:items="lang"
                  v-model="$i18n.locale"
                  color="primary"
                  item-text="name"
                  item-value="val"
                  @change="updateLocale($i18n.locale)"
                  dense
                  outlined
                >
                  <template slot="item" slot-scope="data">
                    <v-img
                      maxHeight="15"
                      maxWidth="15"
                      :src="data.item.img"
                      style="margin-right: 8px;"
                    ></v-img>
                    {{ data.item.name }}
                  </template>
                </v-select>
              </div>
            </div>
          </div>
        </div>
        
        <div class="footer">
          <div
            align="center"
            :class="{
              'ma-0': $vuetify.breakpoint.smAndDown,
              'ma-5': $vuetify.breakpoint.mdAndUp,
            }"
            style="display:flex;justify-content:space-evenly"
          >
            <AcButton
              :outlined="true"
              :name="$t('Login')"
              :onClickHandler="clickLogin"
              :width="'33%'"
            />
            <AcButton
              :name="$t('SignUp')"
              :onClickHandler="register"
              :width="'33%'"
            />
            <!-- <v-btn
              rounded
              class="btn-signup text-capitalize white--text"
              :color="$partnerACL.getButtonColor()"
              @click="register()"
            >
              {{ $t("SignUp") }}
            </v-btn> -->
          </div>
        </div>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import AcButton from "@/components/AcButton";
import Cookies from "js-cookie";
import store from "@/store";
export default {
  components: { AcButton },
  data() {
    return {
      drawer: null,
      //   lang: ["id", "en","vi"],
      lang: [
        {
          name: "Select Languange",
        },
        {
          name: "English (United States)",
          val: "en",
          img: require("@/assets/country/USA.png"),
        },
        {
          name: "Vietnamese",
          val: "vi",
          img: require("@/assets/country/vietnam.png"),
        },
        {
          name:'Indonesia',
          val:"id",
          img:require('@/assets/country/indonesia.png'),
        },
      ],
    };
  },
  created() {
    window.addEventListener("message", this.receiveMessageBuffer);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.receiveMessageBuffer);
  },
  mounted() {
    if (Cookies.get("loggedIn") == "true") {
      if (this.$route.query.token) {
        this.receiveMessage();
      } else {
        this.$router.push({ name: "Home" });
      }
    } else {
      this.receiveMessage();
    }
    store.commit("auth/IS_LOADING", false);
  },

  methods: {
    async receiveMessage() {
      if (this.$route.query.lang) {
        this.$i18n.locale = this.$route.query.lang
        localStorage.setItem('language', this.$route.query.lang);
      }
      if (this.$route.query.token) {
        await this.$store
          .dispatch("auth/refreshTokenBP", { rToken: this.$route.query.token })
          .then((status) => {
            if (status) {
              this.$store
                .dispatch("passenger/GetDataById")
                .then((response) => {
                  let shouldUpdate = response.data.psg_first_name == "n.a" || response.data.psg_last_name == "n.a" || response.data.psg_phone_number == "n.a" || response.data.psg_birthday == "0001-01-01T00:00:00Z"
                  if (shouldUpdate) {
                    // console.log(response);
                    this.$router.push({ name: "RegisterUpdate" });
                  } else {
                    this.$router.push({ name: "Home" });
                  }
                  // console.log(response);
                })
                .catch((err) => {
                  console.log(err);
                });
            } else {
              console.log("Auto Login Failed");
            }
          })
          .catch(() => {
            console.log("Auto Login Failed");
          });
      } else {
        console.log("Auto Login Failed");
      }
      // if (event.data.aBuf) {
      //   var decoder = new TextDecoder("utf-8");
      //   var view = new DataView(event.data.aBuf, 0, event.data.aBuf.byteLength);
      //   var string = decoder.decode(view);
      //   var object = JSON.parse(string);

      // } else {
      //   console.log("nothing");
      // }
    },
    receiveMessageBuffer(event) {
      console.log(event);
      if (event.data.aBuf) {
        var decoder = new TextDecoder("utf-8");
        var view = new DataView(event.data.aBuf, 0, event.data.aBuf.byteLength);
        var string = decoder.decode(view);
        var object = JSON.parse(string);
        this.$store
          .dispatch("auth/refreshTokenBP", { rToken: object.refresh_token })
          .then((status) => {
            if (status) {
              this.$router.push({ name: "Login" });
            } else {
              console.log("nothing");
            }
          });
      }
    },

    register() {
      let registerUrl = this.$store.state.auth.initialPath == null ? '/register' : this.$store.state.auth.initialPath.register
      this.$router.push({
        path: registerUrl,
        query: { partner_id: localStorage.getItem('partner_id') }
      });
    },
    clickLogin() {
      let loginUrl = this.$store.state.auth.initialPath == null ? '/login' : this.$store.state.auth.initialPath.login
      this.$router.push({
        path: loginUrl,
        query: {
          partner_id: localStorage.getItem('partner_id'),
          lang: localStorage.getItem('language')
        }
      });
    },
    updateLocale(locale) {
      localStorage.setItem('language', locale);
    }
  },
};
</script>

<style scoped>
/* If the screen size is 601px or more, set the font-size of <div> to 80px */
@media only screen and (min-width: 601px) {
  .v-text-field .v-input__control .v-input__slot {
    min-height: auto !important;
    display: flex !important;
    align-items: center !important;
  }

  .v-text-field--outlined>>>fieldset {
    color: red !important;
  }

  .body-color {
    background-color: #f5fdf7;
    height: 100%;
    width: 100%;
  }

  .body {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .logo-homepage {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .img-body {
    height: 211px;
    width: 243.77471923828125px;
  }

  .label-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .body-content {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .h3-title {
    font-family: Inter;
    font-size: 30px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #4bb14e;
  }

  .p-text {
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #4bb14e;
  }

  .btn-login {
    /* color: #4bb14e; */
    margin-right: 20%;
    height: 10%;
    width: 50%;
    text-transform: capitalize;
  }

  .btn-signup {
    /* color: #4bb14e;
    background-color: #f5f5f5; */
    margin-left: 5%;
    height: 10%;
    width: 50%;
    text-transform: capitalize;
  }

  .footer {
    padding-top: 2%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10%;
    display: absolute;
    justify-content: center;
    /* background-color: #ffffff; */
  }
}

/* If the screen max size is 600px, set the font-size of <div> to 80px */
@media only screen and (max-width: 600px) {
  .body-color {
    background-color: #f5fdf7;
    height: 100%;
    width: 100%;
  }

  .body {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
  }

  .logo-homepage {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .img-body {
    height: 211px;
    width: 243.77471923828125px;
  }

  .label-content {
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
  }

  .body-content {
    padding-top: 10px;
    padding-bottom: 30px;
  }

  .span-title {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: center;
    color: #4bb14e;
  }

  .p-text {
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
    color: #4bb14e;
  }

  .btn-login {
    color: #4bb14e;
    margin-right: 10%;
    height: 40px;
    width: 30%;
    text-transform: capitalize;
  }

  .btn-signup {
    background-color: #4bb14e;
    /* margin-left: 5%; */
    height: 10%;
    width: 30%;
    text-transform: capitalize;
  }

  .footer {
    padding-top: 9%;
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 16%;
    display: absolute;
    align-items: center;
    justify-content: center;
    /* background-color: #ffffff; */
  }
}
</style>
